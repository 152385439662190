var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("error-content", {
    attrs: {
      code: "401",
      desc: _vm.$t("Oh～您没有浏览这个页面的权限～"),
      src: _vm.src
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }