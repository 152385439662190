var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "role-container"
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 0 } },
        [
          _vm.isGroupUser
            ? [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticStyle: { "padding-bottom": "10px" } },
                    [
                      _c("OrgRoleSelect", {
                        ref: "RoleSelectRef-0",
                        attrs: {
                          options: _vm.gorupOptions,
                          roleIds: _vm.groupRoleIds,
                          hotelVid: "0"
                        },
                        on: { "update:role-ids": _vm.handleGroupRoleUpdate }
                      })
                    ],
                    1
                  )
                ])
              ]
            : _vm._e(),
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c(
                  "el-table",
                  {
                    attrs: { data: _vm.list, border: true, "max-height": "200" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "hotelName", label: _vm.$t("酒店") }
                    }),
                    _c("el-table-column", {
                      attrs: { label: _vm.$t("角色"), width: "400" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("OrgRoleSelect", {
                                ref: `RoleSelectRef-${scope.row.hotelVid}`,
                                attrs: {
                                  options: scope.row.options,
                                  hotelVid: scope.row.hotelVid,
                                  roleIds: scope.row.roleIds
                                }
                              })
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }