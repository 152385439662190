var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.$t("重置密码"),
        "close-on-click-modal": false,
        width: "360px"
      },
      on: { close: _vm.handleClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v(_vm._s(_vm.$t("取消")))
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleConfirm }
                    },
                    [_vm._v(_vm._s(_vm.$t("确定")))]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "pwd-container"
        },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              attrs: {
                "show-message": false,
                model: _vm.formData,
                "label-position": "top",
                size: "mini"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "userPwd", required: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "label-custome" }, [
                                    _c("p", [_vm._v(_vm._s(_vm.$t("新密码")))]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("（您也可以手动设置密码）")
                                        )
                                      )
                                    ])
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("请输入新密码"),
                              clearable: ""
                            },
                            model: {
                              value: _vm.formData.userPwd,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "userPwd", $$v)
                              },
                              expression: "formData.userPwd"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }