<template>
  <div :style="tagStyle" :class="['lz-tag', { 'lz-tag__dot': type==='dot' }]">
    <span :style="tagInnerStyle" class="lz-tag__dot__inner" v-if="type === 'dot'"></span>
    <span class="lz-tag__text">
      <slot></slot>
    </span>
  </div>
</template>

<script>

// 默认颜色列表
const COLOR_LIST = ['primary', 'blue', 'purple', 'green', 'gray', 'orange', 'red'];

/**
 * 自定义 tag 组件
 */
export default {
  name: 'LzTag',
  props: {
    // 标签类型 capsule、dot
    type: {
      type: String,
      default: 'capsule'
    },

    // 标签主色（可自定义）
    color: {
      type: String,
      default: 'primary'
    },
  },
  computed: {
    tagStyle () {
      let colorValue;
      if (this.isPredefineColor(this.color)) {
        colorValue = this.predefineColorValue(this.color);
      } else {
        colorValue = this.color;
      }
      let style = `color: ${this.parseColorToRGBA(colorValue)};`;
      if (this.type === 'capsule') {
        style += `background-color: ${this.parseColorToRGBA(colorValue, 0.15)}`;
      }
      return style;
    },

    tagInnerStyle () {
      let colorValue;
      if (this.isPredefineColor(this.color)) {
        colorValue = this.predefineColorValue(this.color);
      } else {
        colorValue = this.color;
      }
      const style = `background-color: ${colorValue};`;
      return style;
    },
  },
  methods: {
    /**
     * 是否使用预定义 color
     */
    isPredefineColor (color) {
      return COLOR_LIST.includes(color);
    },

    /**
     * 预定义 color value
     */
    predefineColorValue (color, ) {
      let value;
      switch (color) {
        // “蓝色”（基础）
        case 'primary':
        case 'blue':
          value = '#497CF6'
          break;
        // “紫色”
        case 'purple':
          value = '#9370DB';
          break;
        // “绿色”
        case 'green':
          value = '#6ACE96';
          break;
        // “灰色”
        case 'gray':
          value = '#727E92';
          break;
        // “橙色”
        case 'orange':
          value = '#FAAD14';
          break;
        // “红色”
        case 'red':
          value = '#FF4D4F';
          break;
      
        // 默认（基础）
        default:
          value = '#497CF6';
          break;
      }
      return value;
    },

    /**
     * 解析颜色值为 rgba 格式
     * @param { String } color 十六进制颜色值（eg：#ffffff）
     * @param { Number } alpha 透明度
     */
    parseColorToRGBA (color, alpha=1) {
      return `rgba(${parseInt('0x'+color.slice(1, 3))}, ${parseInt('0x'+color.slice(3, 5))}, ${parseInt('0x'+color.slice(5, 7))}, ${alpha})`;
    },
  }
}
</script>

<style lang="scss">
  .lz-tag {
    width: 68px;
    height: 28px;
    display: inline-block;
    line-height: 28px;
    border-radius: 14px;
    text-align: center;
  }

  .lz-tag__dot {
    width: auto;
    height: auto;
    line-height: 1;
    text-align: left;
  }

  .lz-tag__dot__inner {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
  }

  .lz-tag__dot .lz-tag__text {
    color: #474F64;
    vertical-align: middle;
  }
</style>
