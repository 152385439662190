var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      class: { "gorup-select-item": _vm.isGroupRole },
      attrs: {
        value: _vm.roleIds,
        multiple: "",
        loading: _vm.loading,
        "no-data-text": _vm.$t("暂无可选角色")
      },
      on: { change: _vm.handleSelectChange, focus: _vm.handleLoadOptions }
    },
    _vm._l(_vm.options, function(item) {
      return _c("el-option", {
        key: item.value,
        attrs: { label: item.label, value: item.value }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }