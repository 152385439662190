var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-home" }, [
    _c(
      "div",
      {
        staticClass:
          "overview-section display__flex align_items__center justify_content__space_between"
      },
      [
        _c(
          "div",
          { staticClass: "left-view display__flex align_items__center" },
          [
            _c("div", [
              _vm._v(
                _vm._s(_vm.$t("欢迎回来，")) +
                  _vm._s(_vm.userInfo.userName || "")
              )
            ])
          ]
        ),
        _c("div", { staticClass: "right-view" }, [
          _vm._v(_vm._s(_vm.dateDesc()))
        ])
      ]
    ),
    _vm.pageButtonPermission("hrms/home", "addDeptButton") ||
    _vm.pageButtonPermission("hrms/home", "addUserButton")
      ? _c("div", { staticClass: "panel-section" }, [
          _c("div", { staticClass: "panel-title section-stick" }, [
            _vm._v(_vm._s(_vm.$t("快捷入口")))
          ]),
          _c("div", { staticClass: "display__flex flex_wrap__wrap" }, [
            _vm.pageButtonPermission("hrms/home", "addUserButton")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "panel-item panel-animat display__flex flex_direction__column align_items__center justify_content__center",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.goCreateOrg("create-user")
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/create-user.png")
                      }
                    }),
                    _c("div", [_vm._v(_vm._s(_vm.$t("新增员工")))])
                  ]
                )
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "panel-section" }, [
      _c("div", { staticClass: "panel-title section-stick" }, [
        _vm._v(_vm._s(_vm.$t("已订购产品")))
      ]),
      _c(
        "div",
        { staticClass: "display__flex flex_wrap__wrap" },
        _vm._l(_vm.purchaseList, function(item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass:
                "panel-item panel-animat display__flex flex_direction__column align_items__center justify_content__center"
            },
            [
              item.icon ? _c("img", { attrs: { src: item.icon } }) : _vm._e(),
              _c("div", [_vm._v(_vm._s(item.name))])
            ]
          )
        }),
        0
      )
    ]),
    _vm.pageLoading
      ? _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading"
            }
          ],
          staticClass: "loading-mask"
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }