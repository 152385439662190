var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "role-collapse-container" },
    _vm._l(_vm.hotelList, function(item, index) {
      return _c("roleCollapseItem", {
        key: index,
        attrs: {
          item: item,
          "group-name": _vm.groupName,
          "active-role-id": _vm.activeRoleId,
          "is-collapse-show": item.isCollapseShow
        },
        on: {
          "toggle-content-show": _vm.handleToggleContentShow,
          add: _vm.handleAdd,
          "toggle-active": _vm.handleToggleActive,
          edit: _vm.handleEdit
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }