/**
 * stroage的存储封装，方便使用
 */
const defaultKeyPrefix = 'x-inngol-'
const defaultKeyName = 'token'
const defaultExpire = 7 //默认有效期 单位“天”

const _parse = (arg) => {
  try {
    return JSON.parse(arg)
  } catch (e) {
    return undefined
  }
}

const _set = (storage, key, value, expire = defaultExpire) => {
  const _payload = JSON.stringify({
    value,
    t: Date.now() + 1000 * 60 * 60 * 24 * expire
  })
  storage.setItem(key, _payload)
}

const _get = (storage, key) => {
  const res = storage.getItem(key)
  if (res) {
    const data = _parse(res)
    if (data && data.t && data.t > Date.now()) {
      return data.value
    } else {
      storage.removeItem(key) // 移除过期项
    }
  }
  return undefined
}

const _supportStorage = () => {
  return typeof window !== 'undefined' && !!window.localStorage && !!window.sessionStorage
}

export const set = (key = defaultKeyName, value = '', expire = defaultExpire, keyPrefix = defaultKeyPrefix) => {
  if (_supportStorage()) {
    _set(window.localStorage, `${keyPrefix}${key}`, value, expire)
  }
}

export const get = (key = defaultKeyName, keyPrefix = defaultKeyPrefix) => {
  if (_supportStorage()) {
    return _get(window.sessionStorage, `${keyPrefix}${key}`)
  }
  return undefined
}

export const getSession = (key = defaultKeyName, keyPrefix = defaultKeyPrefix) => {
  if (_supportStorage()) {
    return _get(window.sessionStorage, `${keyPrefix}${key}`)
  }
  return undefined
}

export const setSession = (key = defaultKeyName, value = '', expire = defaultExpire, keyPrefix = defaultKeyPrefix) => {
  if (_supportStorage()) {
    _set(window.sessionStorage, `${keyPrefix}${key}`, value, expire)
  }
}

export const remove = (key = defaultKeyName, keyPrefix = defaultKeyPrefix) => {
  if (_supportStorage()) {
    window.localStorage.removeItem(`${keyPrefix}${key}`)
  }
}

export const removeSession = (key = defaultKeyName, keyPrefix = defaultKeyPrefix) => {
  if (_supportStorage()) {
    window.sessionStorage.removeItem(`${keyPrefix}${key}`)
  }
}
