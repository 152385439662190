<template>
<el-container class="main">
    <el-aside width="180px" class="left-sider">
      <div class="system-con display__flex justify_content__center align_items__center">
        <img alt="" v-if="templateMode.logo" class="system-logo-img" :src="templateMode.logo" style="visibility: hidden;"/>
        <span>{{templateMode.name}}</span>
      </div>

      <lz-side-menu :menu-list="menuList" @on-select="turnToPage"></lz-side-menu>
    </el-aside>
    
    <el-container class="layout-content">
      <el-header class="header-con">
        <header-bar :title="userOrgName">
          <template v-slot:user>
            <user :userInfo="userInfo"></user>
            <SwitchLang></SwitchLang>
          </template>
        </header-bar>
      </el-header>

      <el-main class="main-content-con">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapGetters } from 'vuex'
  import LzSideMenu from './components/side-menu'
  import MenuHelper from './components/side-menu/helper.js'
  import HeaderBar from './components/header-bar'
  import User from './components/user'
  import systemHelper from '@/libs/system'
  import basicHelper from '@/view/common/basic'

  export default {
    name: 'Main',
    components: {
      LzSideMenu,
      HeaderBar,
      User,
      SwitchLang: () => import('@/components/SwitchLang.vue')
    },
    computed: {
      ...mapGetters([
        'userInfo',
        'userOrgName'
      ]),

      // 模板信息
      templateMode () {
        return systemHelper.getTemplateMode();
      },

      // 有效菜单列表
      menuList() {
        const menuList = basicHelper.getParsedMenuList();
        const routes = this.$router.options.routes;
        const menuData = MenuHelper.checkMenuData(menuList, routes);
        return menuData;
      }
    },
    methods: {
      /**
       * 侧边菜单触发导航事件
       */
      turnToPage(route) {
        let {name, params, query} = {}
        if (typeof route === 'string') name = route
        else {
          name = route.name
          params = route.params
          query = route.query
        }

        this.$router.push({
          name,
          params,
          query
        }).catch(error=> {
          this.$router.replace({
            path: '/redirect'
          })
        })
      }
    }
  }
</script>

<style lang="scss" src="./main.scss">

</style>
