<template>
<div class="role-container" v-loading="loading">
    <el-row :gutter="0">
      <template v-if="isGroupUser">
        <el-col :span="24">
          <div style="padding-bottom: 10px;">
            <OrgRoleSelect ref="RoleSelectRef-0" :options="gorupOptions" :roleIds="groupRoleIds" hotelVid="0" @update:role-ids="handleGroupRoleUpdate"></OrgRoleSelect>
          </div>
        </el-col>
      </template>
      <template>
        <el-col :span="24">
          <el-table :data="list" :border="true" max-height="200">
            <el-table-column prop="hotelName" :label="$t('酒店')"></el-table-column>
            <el-table-column :label="$t('角色')" width="400">
              <template slot-scope="scope">
                <OrgRoleSelect :ref="`RoleSelectRef-${scope.row.hotelVid}`" :options="scope.row.options" :hotelVid="scope.row.hotelVid" :roleIds="scope.row.roleIds"></OrgRoleSelect>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </template>
    </el-row>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import _ from 'lodash';
import commonApi from '@/view/common/api';
import roleApi from '@/view/organization/api/role';
import OrgRoleSelect from './roleSelect.vue';

export default {
  name: 'OrgRole',

  components: {
    OrgRoleSelect
  },
  inject: ['OrgAccount'],

  provide() {
    return {
      OrgRole: this
    };
  },

  props: {
    role: {
      type: Object,
      default: () => {}
    }
  },

  model: {
    prop: 'role',
    event: 'update:role'
  },

  data() {
    return {
      listAll: [],
      list: [],
      loading: true,
      groupRoleIds: [],
      gorupOptions: [],
      hotelRoleIds: []
      // hotelXHRResultList: null,
    };
  },
  computed: {
    isGroupUser() {
      //是否是集团用户
      return !!_.find(this.listAll, (item) => item.hotelVid == 0);
    }
  },

  methods: {
    handleGroupRoleUpdate(val) {
      this.groupRoleIds = val;
      this.handleEmitChange();
    },
    handleHotelRoleUpdata(val, hotelVid) {
      let aIdx = _.findIndex(this.list, (it) => it.hotelVid == hotelVid);
      if (aIdx > -1) {
        this.list[aIdx].roleIds = val;
        this.handleEmitChange();
      } else {
        this.$notice.error(intl.$t("更新酒店选择错误！"));
      }
    },
    handleEmitChange() {
      let hotelRoleList = [];
      this.list.forEach((item) => {
        if (item.roleIds && item.roleIds.length > 0) {
          hotelRoleList.push({
            hotelVid: item.hotelVid,
            roleIds: item.roleIds
          });
        }
      });
      const role = _.assign({}, this.role, _.cloneDeep({ roleIds: this.groupRoleIds, hotelRoleList }));

      this.$emit('update:role', _.cloneDeep(role));
    },
    getHotelList() {
      // 修改成promise
      //获取酒店列表
      return new Promise((reslove, reject) => {
        try {
          this.loading = true;
          commonApi.
          hotelListByName().
          then((res) => {
            if (res.code == 200) {
              if (res.data) {
                this.listAll = res.data || [];
                this.list = _.filter(res.data || [], (it) => it.hotelVid != 0).map((item) => {
                  return {
                    ...item,
                    options: [],
                    roleIds: []
                  };
                });
                reslove(_.clone(this.listAll));
              } else {
                this.liatAll = [];
                this.list = [];
                this.$notice.error(intl.$t("获取当前登录用户权限下的所有酒店数据失败"));
              }
            } else {
              this.$notice.error(res.message || intl.$t("获取当前登录用户权限下的所有酒店数据失败"));
              this.liatAll = [];
              this.list = [];
              reject(new Error(res.message || intl.$t("获取当前登录用户权限下的所有酒店数据失败")));
            }
          }).
          catch((err) => {
            console.error(intl.$t("获取当前登录用户权限下的所有酒店数据失败"), err);
            this.liatAll = [];
            this.list = [];
            reject(err);
          }).
          finally(() => {
            this.loading = false;
          });
        } catch (err) {
          console.error(intl.$t("获取当前登录用户权限下的所有酒店数据失败"), err);
          this.liatAll = [];
          this.list = [];
          reject(err);
        }
      });
    },
    getRoleList(hotelVid) {
      return new Promise((reslove, reject) => {
        try {
          // if (this.hotelXHRResultList && this.hotelXHRResultList[hotelVid]) {
          //   let _res = this.hotelXHRResultList[hotelVid]
          //   if (hotelVid == 0) {
          //     this.gorupOptions = _.clone(_res).map((it) => {
          //       return {
          //         label: it.roleName,
          //         value: it.id,
          //       }
          //     })
          //   } else {
          //     let aIdx = _.findIndex(this.list, (it) => it.hotelVid == hotelVid)
          //     if (aIdx > -1) {
          //       this.list[aIdx].options = _.clone(_res).map((it) => {
          //         return {
          //           label: it.roleName,
          //           value: it.id,
          //         }
          //       })
          //     }
          //   }
          //   reslove(_.clone(_res))
          //   return false
          // }
          roleApi.
          roleHotel({
            hotelVid
          }).
          then((res) => {
            if (res.code == 200) {
              if (hotelVid == 0) {
                this.gorupOptions = res.data.map((it) => {
                  return {
                    label: it.roleName,
                    value: it.id
                  };
                });
              } else {
                // 处理自动填充
                let aIdx = _.findIndex(this.list, (it) => it.hotelVid == hotelVid);
                if (aIdx > -1) {
                  this.list[aIdx].options = res.data.map((it) => {
                    return {
                      label: it.roleName,
                      value: it.id
                    };
                  });
                }
              }
              // this.hotelXHRResultList = {...(this.hotelXHRResultList || {}), ...{[hotelVid]: res.data}}
              reslove(_.cloneDeep(res.data));
            } else {
              reject(new Error(res.message || intl.$t("获取角色失败")));
            }
          }).
          catch((err) => {
            console.error(intl.$t("获取角色失败"), err);
            reject(err);
          });
        } catch (err) {
          console.error(intl.$t("获取角色失败"), err);
          reject(err);
        }
      });
    },
    setRoleData(roleObj) {
      // 编辑时，需要填充数据
      if (roleObj.roleIds && roleObj.roleIds.length > 0) {
        this.getRoleList(0);
        this.groupRoleIds = roleObj.roleIds || [];
      } else {
        this.groupRoleIds = [];
      }
      if (roleObj.hotelRoleList && roleObj.hotelRoleList.length > 0) {
        roleObj.hotelRoleList.forEach((item) => {
          let aIdx = _.findIndex(this.list, (it) => it.hotelVid == item.hotelVid);
          if (aIdx > -1) {
            this.getRoleList(item.hotelVid);
            this.list[aIdx].roleIds = item.roleIds;
          }
        });
      } else {
        this.list = this.list.map((it) => ({
          ...it,
          roleIds: []
        }));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.role-container {
  padding: 0;
  :deep(.el-table th.el-table__cell > .cell) {
    padding-left: 14px !important;
  }
  :deep(.el-table .el-table__cell) {
    padding: 6px 0 !important;
  }
  :deep(.el-table td.el-table__cell),
  :deep(.el-table th.el-table__cell.is-leaf) {
    border-color: #E5E8F0 !important;
  }
  :deep(.el-table--fit) {
    border-color: #E5E8F0 !important;
  }
  :deep(.el-table th.el-table__cell) {
    background-color: #F5F7FD !important;
  }
  :deep(.el-table .el-table__cell) {
    font-weight: 400;
    font-size: 14px;
    color: #575B6B;
  }
  :deep(.el-table th.el-table__cell > .cell){
    font-weight: 500;
    font-size: 14px;
    color: #283145;
  }
}
</style>
