import Node from './node'

export default class NodeStore {
  constructor (options) {
    this.nodesMap = {};
    
    for (const name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name];
      }
    }

    this.root = new Node({
      data: this.data,
      store: this
    });
  }

  getNode (data) {
    if (data instanceof Node) {
      return data;
    }
    let key = typeof data !== 'object' ? data : data[this.key];
    return this.nodesMap[key] || null;
  }

  registerNode (node) {
    let nodeKey = node.key;
    if (nodeKey) {
      this.nodesMap[nodeKey] = node;
    }
  }
}
