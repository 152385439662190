import { render, staticRenderFns } from "./password.vue?vue&type=template&id=39c44732"
import script from "./password.vue?vue&type=script&lang=js"
export * from "./password.vue?vue&type=script&lang=js"
import style0 from "./password.vue?vue&type=style&index=0&id=39c44732&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/sangyoutao/workspace/abroad-hrms-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39c44732')) {
      api.createRecord('39c44732', component.options)
    } else {
      api.reload('39c44732', component.options)
    }
    module.hot.accept("./password.vue?vue&type=template&id=39c44732", function () {
      api.rerender('39c44732', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/public/main/components/password/password.vue"
export default component.exports