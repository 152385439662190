/**
 * 原本以为setCooke、getCookie以及clearCookie，应该将key抛出作为参数，想要新增一个cookie，只需要传入key即可
 * 但是在这个项目的storage里，cookie的key是固定的
 * 所以 我新增了一个cookie的方法，用于其他的cookie的操作
 * 原本cookie的操作，通过'js-cookie'这个库来操作,因为我们二级域名不同，需要设置cookie的domain
 * 所以 这个文件里的cookie的设置方法，是将domain处理完以后，将cookie的key和value传入
 * 所有的cookie都是在js-cookie的功能逻辑上，二次封装，将js-cookie封装以后，domain默认当前项目的一级目录
 * 在特殊情况下，比如：development下，不对domain进行干扰处理
 **/
import cookies from 'js-cookie'

const cookName = 'x-access-titc-s-token'
// const isDev = (process.env?.VITE_ENV || "development") === "development"
const getHostName = () => {
    const hostname = window.location.hostname
  
    // 正则表达式匹配IP地址
    const ipPattern =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  
    // 检查是否为本地主机名或IP地址
    if (hostname === 'localhost' || ipPattern.test(hostname)) {
      // 返回完整的IP地址或本地主机名，不带端口
      return hostname
    } else {
      // 分割域名
      const parts = hostname.split('.')
      if (parts.length > 1) {
        // 返回一级域名，例如 example.com
        return parts.slice(-2).join('.')
      } else {
        // 返回整个域名（尽管不太可能发生这种情况）
        return hostname
      }
    }
  }
  

const cookie = {
	get: (key = cookName) => {
        return cookies.get(key)
    },
	set: (key = cookName, value = '', options = {}) =>  {
        const domain = getHostName()
        cookies.set(key, value, { domain, ...options })
    },
	remove: (key = cookName, options = {}) => {
        const domain = getHostName()
        cookies.remove(key, { domain, ...options })
    }
}

export default cookie
