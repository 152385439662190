var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("error-content", {
    attrs: {
      code: "404",
      desc: _vm.$t("Oh～您的页面好像飞走了～"),
      src: _vm.src
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }