import storage from './storage'

/**
 * 本地缓存模块
 * @module libs/cache
 * 
 * // 使用方式
 * cache.cookie().get(name)
 * cache.cookie().set(name, value, attributes)
 * cache.cookie().remove(name)
 * 
 * cache.local().get(key)
 * cache.local().set(key, value)
 * cache.local().remove(key)
 * cache.local().clear()
 * 
 * cache.session().get(key)
 * cache.session().set(key, value)
 * cache.session().remove(key)
 */
export default {
  // cookie: function () {
  //   return require('./cookie.js').default;
  // },

  local: function (isolate) {
    return storage('local', isolate);
  },

  session: function (isolate) {
    return storage('session', isolate);
  }
}