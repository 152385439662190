<template>
  <div class="node-cascader-panel">
    <div v-for="(nodes, index) in menus" :key="index" class="cascader-menu">
      <div class="cascader-menu__wrap">
        <ul class="cascader-menu__list">
          <li
            v-for="node in nodes"
            :key="node.key"
            class="cascader-menu__item"
            :class="{ 'in-active-path': isInActivePath(index, node) }"
            @click="handleNodeClick(index, node)">
            <slot :node="node"></slot>
            <i v-if="!node[props.leaf]" class="el-icon-arrow-right cascader-node__suffix"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 权限级联组件
 */
export default {
  props: {
    props: {
      type: Object
    },

    options: {
      type: Array
    }
  },
  data () {
    return {
      menus: [],
      activePath: []
    }
  },
  mounted () {
    this.initData();
  },
  methods: {
    initData () {
      this.menus = [this.options];
    },

    isInActivePath (index, node) {
      let activePathNode = this.activePath[index] || {};
      return activePathNode.key === node.key;
    },

    /**
     * node 节点点击事件
     * @param { Number } index panel menu 索引
     * @param { Object } node 节点对象
     */
    handleNodeClick (index, node) {
      let childNodes = node[this.props.children];
      let activePath = this.activePath.slice(0, index);
      activePath.push(node);
      let menus = this.menus.slice(0, index+1);
      if (!node[this.props.leaf]) {
        menus.push(childNodes);
      }
      this.activePath = activePath;
      this.menus = menus;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/style/const";

  .node-cascader-panel {
    display: flex;
  }

  .cascader-menu {
    width: 180px;
    max-width: 180px;
  }

  .cascader-menu + .cascader-menu {
    border-left: 1px solid $border-color;
  }

  .cascader-menu__wrap {
    height: 200px;
    overflow: auto;
  }

  .cascader-menu__list {
    height: 100%;
    padding: 6px 0;
  }

  .cascader-menu__item {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 20px;
    height: 32px;
    line-height: 32px;

    $_background-color: #F5F7FA;
    &:hover {
      background-color: $_background-color;
    }

    &.in-active-path {
      background-color: $_background-color;
    }

    .cascader-node__label {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cascader-node__suffix {
      position: absolute;
      right: 10px;
    }
  }
</style>
