var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-avatar-dropdown pr-20" },
    [
      _c(
        "el-dropdown",
        {
          attrs: { placement: "bottom" },
          on: {
            command: _vm.handleDropdownCommand,
            "visible-change": _vm.handleDropdownVisibleChanged
          }
        },
        [
          _c("div", { staticClass: "avatar-view" }, [
            _c("img", { attrs: { src: _vm.getUserAvator() } }),
            _c("label", [_vm._v(_vm._s(_vm.userInfo.userName || ""))]),
            _c("div", { staticClass: "triangle-view" }, [
              _c("div", {
                class: ["triangle-item", { rotate: _vm.dropdownVisible }]
              })
            ])
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "logout" } }, [
                _vm._v(_vm._s(_vm.$t("退出登录")))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "lz-dialog",
        {
          staticClass: "change-password-dialog",
          attrs: {
            name: "change-password",
            width: "360px",
            "footer-hide": "",
            "mask-closable": !_vm.dialogLoading,
            "loading-enable": "",
            loading: _vm.dialogLoading
          },
          on: { change: _vm.handleDialogVisibleChangeCallback },
          model: {
            value: _vm.showPasswordDialog,
            callback: function($$v) {
              _vm.showPasswordDialog = $$v
            },
            expression: "showPasswordDialog"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _vm.passwordDialogVisible
                ? _c("change-password", {
                    on: { loading: loading => (_vm.dialogLoading = loading) },
                    model: {
                      value: _vm.showPasswordDialog,
                      callback: function($$v) {
                        _vm.showPasswordDialog = $$v
                      },
                      expression: "showPasswordDialog"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("lz-dialog", {
        staticClass: "logout-dialog",
        attrs: {
          name: "logout",
          title: _vm.$t("是否退出登录？"),
          content: _vm.$t(
            "退出登录后，您将退出所有系统。如仅需退出当前系统，请点取消后关闭浏览器窗口。"
          ),
          confirmButtonText: _vm.$t("退出登录"),
          "mask-closable": !_vm.dialogLoading,
          "loading-enable": "",
          loading: _vm.dialogLoading
        },
        on: {
          change: _vm.handleDialogVisibleChangeCallback,
          confirm: _vm.handleLogoutConfirm
        },
        model: {
          value: _vm.showLogoutDialog,
          callback: function($$v) {
            _vm.showLogoutDialog = $$v
          },
          expression: "showLogoutDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }