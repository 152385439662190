/**
 * Permission mixin
 */
export default {
  data () {
    return {
      checkAll: false,
      checkIndeterminate: false,

      // 项目权限数据
      ppData: []
    }
  },
  computed: {
    // 当前选中的权限数据
    activePermissionData () {
      return this.ppData.find(item => item.projectCode === this.activeProjectCode);
    }
  },
  methods: {
    /**
     * 获取项目 tree data
     * @param { String } code project code
     * @returns { Array } tree data
     */
    getProjectTreeData (code) {
      return this.projectData.find(item => item.projectCode == code).resources;
    },

    /**
     * 处理权限勾选数据和勾选状态
     */
    processActiveCheckDataAndState () {
      this.$nextTick(() => {
        this.setPermissionCheckData(this.activeProjectCode);
        this.processRestStateIfNeed(this.activeProjectCode);
        this.setPermissionNodeCount();
        this.syncCheckAllState();
      });
    },

    /**
     * 设置项目权限勾选数据
     * @param { String } code project code
     */
    setPermissionCheckData (code) {
      let index = this.ppData.findIndex(item => item.projectCode == code);
      if (index === -1) {
        let treeData = this.getProjectTreeData(code);
        let checkedKeys = this.permissionViewRef.getProjectCheckedKeys(treeData);
        // 处理特殊权限（如：“餐饮-餐厅”）
        let specialCheckedKeys = [];
        if (this.isProjectPOS(code)) {
          this.restRes.forEach(restItem => {
            let index = checkedKeys.findIndex(key => key == restItem.resourceId);
            if (index !== -1) {
              specialCheckedKeys.push(checkedKeys[index]);
              checkedKeys.splice(index, 1);
            }
          });
        }
        this.ppData.push({
          projectCode: code,
          checkedKeys: checkedKeys,
          specialCheckedKeys: specialCheckedKeys
        });
      }
    },

    /**
     * 缓存 tree 节点总数量
     */
    setPermissionNodeCount () {
      if (!this.activePermissionData.nodeCount) {
        let count = this.permissionViewRef.getProjectNodeCount() - this.deleteRestCount;
        this.activePermissionData.nodeCount = count;
      }
    },

    /**
     * 同步“全部选择” checkbox 状态
     */
    syncCheckAllState () {
      const checkedKeys = this.activePermissionData.checkedKeys;
      const specialCheckedKeys = this.activePermissionData.specialCheckedKeys;
      const count = this.activePermissionData.nodeCount;
      if (!checkedKeys.length) {
        this.checkAll = false;
        this.checkIndeterminate = false;
      } else if (checkedKeys.length+specialCheckedKeys.length === count) {
        this.checkAll = true;
        this.checkIndeterminate = false;
      } else {
        this.checkAll = false;
        this.checkIndeterminate = true;
      }
    },

    /**
     * 同步项目权限勾选状态
     * @param { Number|String } key 资源 key
     * @param { Object } data 资源 data
     * @param { Boolean } checked 是否勾选
     */
    syncPermissionCheckState (key, data, checked) {
      if (this.isProjectPOS(this.activeProjectCode) && this.isDataRest(data)) {
        this.syncSpecialPermissionCheckState(this.activeProjectCode, key, checked);
      } else {
        const checkedKeys = this.activePermissionData.checkedKeys;
        const index = checkedKeys.findIndex(checkedKey => checkedKey == key);
        if (checked && index===-1) {
          checkedKeys.push(key);
        } else if (!checked && index!==-1) {
          checkedKeys.splice(index, 1);
        }
      }
    },

    /**
     * 同步特殊项目权限勾选状态
     * @param { Number|String } key 资源 key
     * @param { Boolean } checked 是否勾选
     */
    syncSpecialPermissionCheckState (projectCode, key, checked) {
      let ppItem = this.ppData.find(item => item.projectCode === projectCode);
      const specialCheckedKeys = ppItem.specialCheckedKeys;
      const index = specialCheckedKeys.findIndex(checkedKey => checkedKey == key);
      if (checked && index===-1) {
        specialCheckedKeys.push(key);
      } else if (!checked && index!==-1) {
        specialCheckedKeys.splice(index, 1);
      }
    }
  }
}
