<template>
<div :class="{'role-item':true,'active':isActive}" @click="handleToggleActive">
        <div class="role-item-left">
            <div class="iconify lz-icon-kehuguanli">
                <IconifyIcon icon="lz-icon-kehuguanli"></IconifyIcon>
            </div>
            <el-tooltip :disabled="role.label.length<10" :enterable="false" :content="role.label" placement="top">
                <div class="role-label">
                    {{role.label}}
                </div>
            </el-tooltip>
        </div>
        <div class="role-btn" @click.prevent.stop="handleEdit">
            <IconifyIcon icon="lz-icon-bianji"></IconifyIcon>
        </div>
    </div>
</template>

<script>
export default {
    name: 'roleCollapseItemChild',
    props:{
        role:{
            type:Object,
            default:()=>({})
        },
        activeRoleId:{
            type:String,
            default:''
        }
    },
    computed:{
        isActive() {
           return this.role.value == this.activeRoleId
        }
    },
    methods:{
        handleToggleActive() {
            if(this.isActive) return
            this.$emit('toggle-active', this.role.value)
        },
        handleEdit() {
            this.$emit('edit', this.role)
        }
    }
}
</script>

<style lang="scss" scoped>
.role-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
            &:not(.active){
                background-color: #EBEDEF;
            }
            .role-btn {
                visibility: visible;
                color: #497CF6;
            }
        }
        &.active {
            background-color: #497CF6;
            .role-label{
                color: #fff;
            }
            .lz-icon-kehuguanli{
                color: #fff;
            }
            .role-btn {
                visibility: visible;
                color: #fff;
            }
        }
    }
    .role-item-left{
        display: flex;
        flex: 1;
        align-items: center;
        padding: 8px 0 8px 16px;
        .lz-icon-kehuguanli{
            width: 10px;
            height: 10px;
            color: #497CF6;
            margin-right: 7px;
            :deep(svg){
                display: block;
            }
        }
    }
    .role-label{
        font-weight: 400;
        font-size: 14px;
        color: #575B6B;
        line-height: 20px;
        flex: 1;
        max-width: 128px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .role-btn {
        visibility: hidden;
        padding: 8px 16px 8px 16px;
    }
</style>
