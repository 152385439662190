import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import config from '@/config'
import axios from 'axios'
import ElementUI from 'element-ui'
import LzPagePanel from '_c/page-panel'
import LzDrawer from '_c/drawer'
import LzDialog from '_c/dialog'
import LzModal from '_c/modal'
import LzIcon from '_c/icon'
import LzPagination from '_c/pagination'
import LzTag from '_c/tag'
import LzNotice from '_c/notice'
import IconifyIcon from './view/public/icons/index.js'

import Tci18nVue2, { useI18n } from '@tci18n/vue2';

// 过滤器
import '@/common/filter'

// 全局样式
import '@/assets/css/base.scss'
import '@/style/common.css'
import '@/style/element_ui_reset.scss'
import '@/style/global.scss'
// icon font 样式
import '@/assets/icons/iconfont.css'
// LzNotice 样式
import '_c/notice/index.scss'

Vue.prototype.$axios = axios //全局配置
Vue.prototype.$config = config //全局配置

Vue.use(Tci18nVue2, {
  locale: __tci18n_locale__,
  langs: __tci18n_langs__
})

const { elementLang } = useI18n()

Vue.use(ElementUI, { locale: elementLang })
// 引入全局自定义组件
Vue.use(LzPagePanel)
Vue.use(LzDrawer)
Vue.use(LzDialog)
Vue.use(LzModal)
Vue.use(LzIcon)
Vue.use(LzPagination)
Vue.use(LzTag)
Vue.use(IconifyIcon)
Vue.prototype.$notice = LzNotice


new Vue({
  render: h => h(App),
  router,
  store,
  i18n: Vue.prototype.tci18n,
}).$mount('#app')
