<template>
    <div class="role-collapse-item">
       <div :class="{'r-c-i-header': true,'is-show':item.isCollapseShow}">
        <div class="rcih-left" @click="handleToggleContentShow">
          <div class="iconify-icon lz-icon-xiala">
            <IconifyIcon icon="lz-icon-xiala"/>
          </div>
          <div class="iconify-icon lz-icon-qiye">
            <IconifyIcon icon="lz-icon-qiye"/>
          </div>
          <el-tooltip :disabled="showName.length<10" :enterable='false' :content="showName" placement="top">
          <div class="tit">
            <span>{{showName}}</span>
          </div>
        </el-tooltip>
        </div>
        <div class="iconify-icon lz-icon-jia" @click="handleAdd">
            <IconifyIcon icon="lz-icon-jia"/>
        </div>
       </div>
       <el-collapse-transition>
        <div v-show="item.isCollapseShow" :class="{'r-c-i-content': true,'is-show':item.isCollapseShow}" v-loading="!item.loaded">
            <template v-if="item.roles && item.roles.length > 0 && item.loaded">
                <item-child 
                    v-for="role in item.roles" 
                    :id="`${item.hotelVid}-${role.value}`" 
                    :active-role-id="activeRoleId" 
                    :role="role" 
                    :key="`${item.hotelVid}-${role.value}`"
                    @toggle-active="handleToggleActive"
                    @edit="handleEdit"
                    />
            </template>
        </div>
         </el-collapse-transition>
    </div>
</template>
<script>
import itemChild from  './item-child.vue'
export default {
    name: 'roleCollapseItem',
    props:{
        item:{
            type:Object,
            default:()=>({})
        },
        groupName:{
            type:String,
            default:''
        },
        activeRoleId:{
            type:String,
            default:''
        },
        isCollapseShow:{
            type:Boolean,
            default:false
        }
    },
    components:{
        itemChild
    },
    computed:{
        showName(){
            return this.item.hotelVid != '0' ? this.item.hotelName : this.groupName
        }
    },
    methods:{
        handleToggleContentShow(){
            this.$emit('toggle-content-show', this.item, !this.isCollapseShow)
        },
        handleAdd(){
            this.$emit('add', this.item)
        },
        handleToggleActive(roleId){
            this.$emit('toggle-active',roleId, this.item)
        },
        handleEdit(role){
            this.$emit('edit',role, this.item)
        }
    }
}
</script>

<style lang="scss" scoped>
.r-c-i-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    &.is-show{
        .lz-icon-xiala {
            transform: rotate(0deg);
        }
    }
    .rcih-left{
        display: flex;
        flex:1;
        align-items: center;
        cursor: pointer;
    }
    .lz-icon-xiala {
        width: 6px;
        height: 6px;
        transform-origin: center;
        transform: rotate(270deg);
        color: #283145;
        :deep(svg){
            display: block;
        }
    }
    .lz-icon-qiye{
        margin-left: 6px;
        margin-right: 6px;
        width: 11px;
        height: 12px;
        color: #497CF6;
        :deep(svg){
            display: block;
        }
    }
    .tit{
        font-weight: 500;
        font-size: 16px;
        color: #283145;
        line-height: 22px;
        max-width: 142px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .lz-icon-jia{
        color: #497CF6;
        height: 12px;
        width: 12px;
        cursor: pointer;
        padding: 5px;
        padding-left: 15px;
        box-sizing: content-box;
        :deep(svg){
            display: block;
        }
    }
    
}
.r-c-i-content {
    display: flex;
    flex-direction: column;
    background: #F8F9FB;
    margin: 8px 0;
    box-sizing: border-box;

    // transition: 0.3s height ease-in-out, 0.3s padding-top ease-in-out, 0.3s padding-bottom ease-in-out;
    &.is-hide{
        display: none;
    }
    :deep(.el-loading-spinner){
        margin-top: 0;
    }
    :deep(.el-loading-spinner .circular) {
        height: 28px;
        width: 28px;
    }
    :deep(.el-empty__description) {
        margin-top: 5px;
    }
}
</style>