<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

// import systemHelper from '@/libs/system'
// import chainHelper from '@/view/common/chain'
// import {setStorage} from "@/utils/storage";

// import {getTokenFromCookie} from '@/libs/oauth'

export default {
  name: 'App',
  created () {
    // systemHelper.setFavicon();
    // chainHelper.parseChainDataInURL();
  },
  // watch: {
  //   '$route.query': function(n) {
  //     if(n?.pCode) {
  //       setStorage('hrms_pro_code', n.pCode)
  //     }
  //   },
  // },
  mounted() {
    // const token = getTokenFromCookie()
    // if(token) {
    //   setStorage('hrms_to', token)
    // }
  },
  methods: {

  }
}
</script>

<style>
#app,
body,
html {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  color: #474F64;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
