var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { "role-item": true, active: _vm.isActive },
      on: { click: _vm.handleToggleActive }
    },
    [
      _c(
        "div",
        { staticClass: "role-item-left" },
        [
          _c(
            "div",
            { staticClass: "iconify lz-icon-kehuguanli" },
            [_c("IconifyIcon", { attrs: { icon: "lz-icon-kehuguanli" } })],
            1
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                disabled: _vm.role.label.length < 10,
                enterable: false,
                content: _vm.role.label,
                placement: "top"
              }
            },
            [
              _c("div", { staticClass: "role-label" }, [
                _vm._v(" " + _vm._s(_vm.role.label) + " ")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "role-btn",
          on: {
            click: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.handleEdit.apply(null, arguments)
            }
          }
        },
        [_c("IconifyIcon", { attrs: { icon: "lz-icon-bianji" } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }