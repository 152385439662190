
let nodeIdSeed = 0;
/**
 * 父子节点树数据结构
 */

export default class Node {
  constructor (options) {
    this.id = nodeIdSeed++;
    this.checked = false;
    this.expanded = false;
    this.data = null;
    this.parent = null;
    this.isLeaf = false;
    this.level = 0;
    this.childNodes = [];

    for (const name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name];
      }
    }

    if (this.store) {
      this.store.registerNode(this);
    }

    if (this.data) {
      this.setData(this.data);
    }
  }

  get key () {
    let nodeKey = this.store.key;
    if (this.data) {
      return this.data[nodeKey];
    }
    return null;
  }

  get disabled () {
    return this.data.disabled;
  }

  get visible () {
    return !this.data.deleted;
  }

  get children () {
    return !this.isLeaf ? this.childNodes : [];
  }

  setData (data) {
    let children;
    if (this.level===0 && Array.isArray(data)) {
      children = data;
    } else {
      children = data.children || [];
    }
    this.data = data;
    if (!children.length) {
      this.isLeaf = true;
    } else {
      for (let i = 0; i < children.length; i++) {
        this.insertNode(children[i]);
      }
    }
  }

  insertNode (data) {
    let child = { data, store: this.store };
    child.checked = data.checked;
    child.level = this.level + 1;
    let node = new Node(child);
    node.parent = this;
    this.childNodes.push(node);
  }


  collapse () {
    this.expanded = false;
  }

  expand () {
    this.expanded = true;
  }
}
