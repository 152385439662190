import config from '@/config'
import Ajax from '@/libs/ajax'

const ajax = new Ajax(config.serverConfig.serviceURL);

// 成员列表（分页）
const memberListPage = ajax.post(config.apiConf.serviceApi + 'userInfo/pageUser');

// 成员列表（角色）
const memberListNewPage = ajax.post(config.apiConf.serviceApi + 'userInfo/pageUserNew');

// 成员列表（角色）
const memberListByRole = ajax.post(config.apiConf.serviceApi + 'userInfo/listUserByRoleId');
// 成员列表（部门）
const memberListByDept = ajax.post(config.apiConf.serviceApi + 'userInfo/listUserByDeptId');
// 成员搜索
const memberSearch = ajax.post(config.apiConf.serviceApi + 'userInfo/fuzzyQueryByRoleId');
// 成员调整部门
const memberDeptUpdate = ajax.post(config.apiConf.serviceApi + 'userInfo/updateDept');
// 成员删除
const memberDelete = ajax.post(config.apiConf.serviceApi + 'userInfo/delete');
// 成员详情
const memberInfo = ajax.post(config.apiConf.serviceApi + 'userInfo/getById');
// 成员保存
const memberSave = ajax.post(config.apiConf.serviceApi + 'userInfo/save');
// 自动生成账号
const memberAccountCreate = ajax.post(config.apiConf.serviceApi + 'userInfo/createAccount');
// 解绑微信号
const memberUnbindWX = ajax.post(config.apiConf.serviceApi + 'userInfo/unbindWx');
// 成员状态修改
const memberStateUpdate = ajax.post(config.apiConf.serviceApi + 'userInfo/updateState');

// 成员添加
const memberAdd = ajax.post(config.apiConf.serviceApi + 'roleInfo/saveUser');
// 成员移除
const memberRemove = ajax.post(config.apiConf.serviceApi + 'roleInfo/removeUser');


export default {
  memberListPage,
  memberListNewPage,
  memberListByRole,
  memberListByDept,
  memberSearch,
  memberDeptUpdate,
  memberDelete,
  memberInfo,
  memberSave,
  memberAccountCreate,
  memberUnbindWX,
  memberStateUpdate,
  
  memberAdd,
  memberRemove
}
