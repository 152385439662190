<template>
<el-dialog :visible="visible" :title="isEdit ? $t('编辑账户') : $t('新增账户')" :close-on-click-modal="false" @close="handleClose" width="640px">
    <div class="account-container" v-loading="loading">
      <el-form ref="formRef" :show-message="false" :model="formData" :rules="formRule" label-position="top" size="mini">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('账号')" prop="userAccountName">
              <el-input v-model="formData.userAccountName" :placeholder="$t('请输入账号')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="!isEdit">
            <el-form-item :label="$t('密码')" prop="userPwd">
              <el-input v-model="formData.userPwd" :placeholder="$t('请输入密码')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('姓名')" prop="userName">
              <el-input v-model="formData.userName" :placeholder="$t('请输入姓名')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('电话')">
              <el-input v-model="formData.mobile" :placeholder="$t('请输入电话')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('邮箱')">
              <el-input v-model="formData.email" :placeholder="$t('请输入邮箱')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('状态')">
              <el-switch v-model="formData.state"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="is-required" :label="$t('角色')" prop="role">
              <OrgRole ref="OrgRoleRef" v-model:role="formData.role"></OrgRole>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <div>
        <el-button @click="handleClose">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="handleConfirm">{{$t('确定')}}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { intl } from "@tci18n/vue2";import _ from 'lodash';
import memberAPI from '@/view/organization/api/member';
import OrgRole from './role.vue';

/**
 * 角色操作组件
 * 角色组件，使用v-model双向绑定数据, 通过v-model:role="formData.role"传递数据
 * 因为 roleOperate 组件是一个独立的组件，它是一个formItem里的一个表单项，所以需要通过v-model传递数据
 * 因为使用v-model传递数据，所以需要在组件内部使用model属性，所以编辑时，用ref的set方法去初始化值。这样bian
 * 我在考虑是不是要使用provide/inject来传递数据
 */
export default {
  name: 'OrgAccount', // 组件名称

  components: {
    OrgRole
  },

  provide() {
    return {
      OrgAccount: this
    };
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },

  model: {
    prop: 'visible',
    event: 'update:visible'
  },

  data() {
    return {
      formData: {
        userAccountName: '',
        userPwd: '',
        userName: '',
        mobile: '',
        email: '',
        state: true,
        role: {
          roleIds: [],
          hotelRoleList: []
        }
      },
      formRule: {
        userAccountName: [{ required: true, message: intl.$t("请输入账号"), trigger: 'change' }],
        userPwd: [{ required: true, message: intl.$t("请输入密码"), trigger: 'change' }],
        userName: [{ required: true, message: intl.$t("请输入姓名"), trigger: 'change' }],
        role: [
        {
          validator: (rule, value, callback) => {
            if (value && (value.roleIds && value.roleIds.length > 0 || value.hotelRoleList && value.hotelRoleList.length > 0)) {
              callback();
            } else {
              callback(new Error(intl.$t("请至少选择一个角色")));
            }
          },
          message: intl.$t("请至少选择一个角色"),
          trigger: 'change'
        }]

      },
      loading: false
    };
  },
  computed: {
    isEdit() {
      //如果填充了数据了，就说明需要编辑数据
      return !!(this.detail && this.detail.userId && `${this.detail.userId}`.length > 0);
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          if (this.$refs['OrgRoleRef'] && this.$refs['OrgRoleRef'].getHotelList) {
            this.$refs['OrgRoleRef'].getHotelList().then(() => {
              if (this.isEdit) {
                this.getDetail();
              } else {
                this.handleResrtFields();
              }
            });
          } else {
            this.$nextTick(() => {
              if (this.isEdit) {
                this.getDetail();
              } else {
                this.handleResrtFields();
              }
            });
          }
        });

      }
    }
  },

  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },

    handleConfirm() {
      this.$refs['formRef'].validate((valid) => {
        if (valid) {
          let params = {
            userId: this.formData.userId || '',
            email: this.formData.email || '',
            mobile: this.formData.mobile || '',
            state: this.formData.state ? 1 : 0,
            userAccountName: this.formData.userAccountName || '',
            userName: this.formData.userName || '',
            userPwd: this.formData.userPwd || '',
            // blocRoleIds:this.formData.role.roleIds || [],
            userHotelRoleList: _.clone(this.formData.role.hotelRoleList || [])
          };
          //   console.log(params)
          if (this.formData.role.roleIds && this.formData.role.roleIds.length > 0) {
            params.userHotelRoleList.push({
              hotelVid: 0,
              roleIds: this.formData.role.roleIds
            });
          }
          //   console.log('params', params)
          memberAPI.
          memberSave(params).
          then((res) => {
            if (res.code == 200) {
              this.$emit('update:visible', false);
              this.$emit('submit:success');

              this.handleResrtFields();
            } else {
              this.$notice.error(res.message);
            }
          }).
          catch((error) => {
            this.$notice.error(error.message || intl.$t("系统异常，请稍后再试"));
            console.error('Save member error: ', error.message);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    handleResrtFields() {
      if (this.$refs['OrgRoleRef'] && this.$refs['OrgRoleRef'].setRoleData) {
        this.$refs['OrgRoleRef'].setRoleData({
          roleIds: [],
          hotelRoleList: []
        });
      }
      this.$nextTick(() => {
        this.$refs['formRef'].resetFields();
      });
    },

    // 获取编辑数据的详细信息
    getDetail() {
      if (!this.isEdit) return;
      try {
        this.loading = true;
        memberAPI.
        memberInfo({ id: this.detail.userId }).
        then((res) => {
          if (res.code == 200 && res.data && res.data.userId) {
            this.setEditData(_.cloneDeep(res.data));
          } else {
            this.$notice.error(res.message);
          }
        }).
        catch((err) => {
          console.error(intl.$t("获取编辑数据详细信息失败"), err);
          this.$emit('update:visible', false);
        }).
        finally(() => {
          this.loading = false;
        });
      } catch (err) {
        console.error(intl.$t("获取编辑数据详细信息失败"), err);
        this.$emit('update:visible', false);
      }
    },

    setEditData(data) {
      this.formData.userId = data.userId;
      this.formData.userAccountName = data.accountUserName || '';
      this.formData.userName = data.userName || '';
      this.formData.mobile = data.mobile || '';
      this.formData.email = data.email || '';
      this.formData.state = data.state == 1;
      const groupRoleObj = _.find(data.hotelList || [], (it) => it.hotelVid == '0');
      let roleObj = {
        roleIds: [],
        hotelRoleList: []
      };
      roleObj.roleIds = _.uniq(groupRoleObj && groupRoleObj.roleList ? groupRoleObj.roleList.map((it) => it.id) : []);
      roleObj.hotelRoleList = _.uniqBy(
        _.filter(data.hotelList || [], (it) => it.hotelVid != '0').map((it) => {
          return {
            hotelVid: it.hotelVid,
            roleIds: _.uniq(it.roleList.map((it) => it.id))
          };
        }),
        'hotelVid'
      );

      this.formData.role = _.clone(roleObj);
      this.$refs['OrgRoleRef'].setRoleData(_.clone(roleObj));
    }
  }
};
</script>

<style lang="scss" scoped>
.account-container {
  margin-top: -20px;
  margin-bottom: -20px;
  :deep(.el-form--label-top .el-form-item__label) {
    padding-bottom: 4px;
  }
  :deep(.el-form-item) {
    margin-bottom: 8px;
  }
}
</style>
