<template>
<div class="node-box">
    <div class="head-view">
      <span class="text ml-20">{{$t('权限列表')}}</span>

      <el-checkbox v-if="data.length" class="ml-20" :value="checkAll" :indeterminate="checkIndeterminate" @change="handleCheckAllChange"><span>{{$t('全部选择')}}</span>
      </el-checkbox>
    </div>

    <div v-if="data.length" class="node-section">
      <div class="body">
        <div v-for="child in data" :key="child.key">
          <div class="row display__flex">
            <div class="col col-head display__flex">
              <div class="cell display__flex align_items__center">
                <div class="triangle-view" @click="handleNodeExpandClick(child)">
                  <i :class="['triangle-item', { rotate: child.expanded }]"></i>
                </div>

                <el-checkbox :title="child.data.resourceName" :value="child.data.checked" @change="checked => handleNodeCheckChange(checked, child)">{{child.data.resourceName}}
                </el-checkbox>
              </div>
            </div>
            <div class="col col-body border-left"></div>
          </div>

          <div v-if="!child.isLeaf" v-show="child.expanded">
            <div class="row row-children display__flex align_items__center" v-for="child in child.children" :key="child.key">
              <div class="col col-head pl-50 display__flex align_items__center">
                <div class="cell">
                  <el-checkbox :title="child.data.resourceName" :value="child.data.checked" @change="checked => handleNodeCheckChange(checked, child)">{{child.data.resourceName}}
                  </el-checkbox>
                </div>
              </div>

              <div class="col col-body border-left pv-13">
                <template v-for="child in child.children">
                  <div class="cell pl-15 mv-5" v-if="child.visible" :key="child.key">
                    <template v-if="child.isLeaf">
                      <el-checkbox :title="child.data.resourceName" :value="child.data.checked" @change="checked => handleNodeCheckChange(checked, child)">{{child.data.resourceName}}
                      </el-checkbox>
                    </template>
                    <template v-else>
                      <el-popover :width="isNodeAllChildLeaf(child) ? 240 : ''" trigger="hover" :popper-class="'node-popover' + (!isNodeAllChildLeaf(child) ? ' has-child' : '')">
                        <label slot="reference" style="display: inline-block;">
                          <el-checkbox class="underline" :title="child.data.resourceName" :value="child.data.checked" @change="checked => handleNodeCheckChange(checked, child)">{{child.data.resourceName}}
                          </el-checkbox>
                        </label>

                        <div class="popover-body display__flex justify_content__space_between flex_wrap__wrap">
                          <template v-if="isNodeAllChildLeaf(child)">
                            <div v-for="child in child.children" :key="child.key" class="popover-cell mv-5">
                              <el-checkbox :title="child.data.resourceName" :value="child.data.checked" @change="checked => handleNodeCheckChange(checked, child)">{{child.data.resourceName}}
                              </el-checkbox>
                            </div>
                          </template>
                          <template v-else>
                            <cascader-panel-view :props="{ children: 'children', leaf: 'isLeaf' }" :options="child.children">
                              <template v-slot="{ node }">
                                <el-checkbox style="padding-right: 6px;" :title="node.data.resourceName" :value="node.data.checked" @click.stop.native="() => {}" @change="checked => handleNodeCheckChange(checked, node)"></el-checkbox>
                                <span :title="node.data.resourceName" class="cascader-node__label">{{node.data.resourceName}}</span>
                              </template>
                            </cascader-panel-view>
                          </template>
                        </div>
                      </el-popover>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>        
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import permissionTreeMixin from './mixins/permissionTree'
import CascaderPanelView from './cascader-panel.vue'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    checkAll: Boolean,
    checkIndeterminate: Boolean
  },
  mixins: [ permissionTreeMixin ],
  components: { CascaderPanelView },
  data () {
    return {
      store: null,
      root: null
    }
  },
  computed: {
    data () {
      return this.root ? this.root.children : [];
    }
  },
  methods: {
    /**
     * 是否所有节点均为“叶子”节点
     * @returns { Boolean } true/fasle
     */
    isNodeAllChildLeaf (node) {
      let leaf = node.allChildLeaf!=undefined ? node.allChildLeaf : node.children.every(item => {
        return item.isLeaf;
      });
      node.allChildLeaf = leaf;
      return leaf;
    },
    

    /**
     * “全部选择” checkbox 改变事件
     * @param { Boolean } checked 是否勾选
     */
    handleCheckAllChange (checked) {
      this.setCheckAllState(checked);
    },

    
    /**
     * 处理勾选状态同步
     */
    handleSyncCheckState (key, data, checked) {
      this.$emit('sync-state', key, data, checked);
    },

    /**
     * 处理勾选全部状态同步
     */
    handleSyncCheckAllState () {
      this.$emit('sync-all-state');
    },



    /** 父组件调用 **/
    /**
     * 设置数据源
     * @param { Object|Null } data 数据源
     */
    setBoxData (data) {
      this.store = data ? data.store : null;
      this.root = data ? data.store.root : null;
    }
  }
}
</script>

<style lang="scss">
.node-popover {
    padding: 0;

    &[x-placement^=bottom] {
      margin-top: 8px;
    }

    &[x-placement^=top] {
      margin-bottom: 8px;
    }
  }
  
  .node-popover.has-child {
    max-width: 360px;

    .popover-body {
      padding: 0;
    }
  }
</style>

<style lang="scss" scoped>
@import "@/style/const";
  @import "@/style/mixins";

  $_row-height: 54px;
  $_cell-width: 157px;
  $_border-color: #E4E8F6;

  .node-box {
    height: 100%;
  }

  .head-view {
    height: $_row-height;
    line-height: $_row-height;
    background-color: #EBF0FA;
    border: 1px solid $_border-color;
  }

  .head-view .text {
    font-weight: bold;
  }

  .head-view ::v-deep .el-checkbox {
    .el-checkbox__label {
      padding-left: 6px;
    }
  }

  .node-section {
    max-height: calc(100% - #{$_row-height});
    overflow: auto;
    border-left: 1px solid $_border-color;
    border-right: 1px solid $_border-color;
  }

  .row {
    min-height: $_row-height;
    border-bottom: 1px solid $_border-color;
  }

  .col-head {
    width: $_cell-width;
    min-height: $_row-height;
  }

  .col-body {
    width: calc(100% - #{$_cell-width});
    min-height: $_row-height;
  }

  .col-body .cell {
    width: $_cell-width / 1.2;
    display: inline-block;
  }

  .popover-body {
    max-height: 230px;
    padding: 9px 14px;
    overflow: auto;

    .popover-cell {
      width: 47%;
      display: inline-block;
    }
  }

  .popover-cell ::v-deep .el-checkbox,
  .node-section ::v-deep .el-checkbox {
    display: flex;
    margin-top: 2px;

    .el-checkbox__label {
      padding-left: 6px;
      padding-right: 4px;
      margin-top: -3px;
      white-space: normal;
      line-height: 1.5;
      @include no-wrap-multi(2);
    }

    &.underline .el-checkbox__label {
      position: relative;

      &::after {
        content: '';
        height: 1px;
        background-color: $text-color;
        position: absolute;
        right: 4px;
        left: 6px;
        bottom: 3px;
      }
    }
  }

  .triangle-view {
    width: 35px;
    height: 30px;
    cursor: pointer;

    .triangle-item {
      width: 5px;
      height: 7px;
      margin-left: 20px;
      margin-top: 11px;
      display: block;

      &::after {
        content: '';
        border-style: solid;
        border-color: transparent $text-color transparent;
        border-width: 3.5px 0 3.5px 5px;
        display: block;
      }

      &.rotate {
        transform: rotate(90deg);
      }
    }
  }

  .border-left {
    border-left: 1px solid $_border-color;
  }

  .pl-50 {
    padding-left: 50px;
  }

  .pv-13 {
    padding-top: 13px;
    padding-bottom: 13px;
  }
</style>
