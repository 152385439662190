import config from '@/config'
import Ajax from '@/libs/ajax'

const ajax = new Ajax(config.serverConfig.serviceURL);

// 首页统计
const getSummary = ajax.post(config.apiConf.serviceApi + 'home/summary')
// 产品列表
const getProductList = ajax.post(config.apiConf.serviceApi + 'home/productList')

export default {
  getSummary,
  getProductList
}
