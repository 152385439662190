
// ZZCRS project code
const ZZCRS_PROJECT_CODE = 'zzcrs';
// POS project code
const POS_PROJECT_CODE = 'pos';

/**
 * Permission special mixin
 */
export default {
  data () {
    return {
      // 餐厅节点资源（数据为引用类型，注意修改会影响其他引用）
      restRes: []
    }
  },
  computed: {
    // deleted 状态“餐厅”数量
    deleteRestCount () {
      return this.isProjectPOS(this.activeProjectCode) ? this.restRes.filter(item => item.deleted).length : 0;
    }
  },
  methods: {
    /**
     * 项目是否不可见
     * @param { String } code project code
     * @returns { Boolean } true/false
     */
    isProjectHidden (code) {
      return this.isProjectZZCRS(code)&&this.isRoleTypeHotel;
    },

    /**
     * 项目是否是不可配置
     * @note 门店角色不可配置 ZZCRS 权限
     * @param { String } code project code
     * @returns { Boolean } true/false
     */
    isProjectDeleted (code) {
      return this.isProjectZZCRS(code) && this.isRoleTypeHotel;
    },

    /**
     * 是否是 “ZZCRS” 项目
     * @param { String } code project code
     * @returns { Boolean } true/false
     */
    isProjectZZCRS (code) {
      return code === ZZCRS_PROJECT_CODE;
    },

    /**
     * 是否是 “POS” 项目
     * @param { String } code project code
     * @returns { Boolean } true/false
     */
    isProjectPOS (code) {
      return code === POS_PROJECT_CODE;
    },

    /**
     * 数据对象是否是“餐厅”
     * @param { Object } data 资源数据对象
     * @returns { Boolean } true/false
     */
    isDataRest (data) {
      return data.hotelVid && data.hotelVid!=0;
    },

    /**
     * 处理 “pos” 项目下的“餐厅”节点资源
     * @param { Object } data 项目数据
     */
    processPOSRestData (data) {
      this.searchRestData(data.resources);
    },

    /**
     * 查找 “pos” 项目下的“餐厅”节点资源
     * @param { Array } data 资源数据
     */
    searchRestData (data) {
      data.forEach(item => {
        if (this.isDataRest(item)) {
          this.$set(item, 'deleted', false);
          this.restRes.push(item);
        }
        if (item.children.length) {
          this.searchRestData(item.children);
        }
      });
    },


    /**
     * 处理“餐厅”数据状态
     * @note “pos” 特殊逻辑：
     * - “门店角色”，权限中的餐厅只展示“绑定酒店”对应的餐厅
     * - “通用角色”，展示所有餐厅
     * @note 不可见数据标记为 deleted
     * 
     * @param { String|Undefined } projectCode 项目 code
     */
    processRestStateIfNeed (projectCode) {
      if (!projectCode) {
        let data = this.projectData.find(item => this.isProjectPOS(item.projectCode));
        projectCode = data && data.projectCode;
      }
      if (this.isProjectPOS(projectCode)) {
        let ppItem = this.ppData.find(item => item.projectCode === projectCode);
        if (this.isRoleTypeHotel && this.hotelVid) {
          this.restRes.forEach(item => {
            item.deleted = item.hotelVid != this.hotelVid;
            if (ppItem) {
              let checked = item.deleted ? false : item.checked;
              this.syncSpecialPermissionCheckState(projectCode, item.resourceId, checked);
            }
          });
        } else {
          this.restRes.forEach(item => {
            item.deleted = false;
            if (ppItem) this.syncSpecialPermissionCheckState(projectCode, item.resourceId, item.checked);
          });
        }
        // 重置 tree 节点总数量，以重新计算
        if (ppItem) ppItem.nodeCount = 0;
      }
    }
  }
}
