var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "role-collapse-item" },
    [
      _c(
        "div",
        { class: { "r-c-i-header": true, "is-show": _vm.item.isCollapseShow } },
        [
          _c(
            "div",
            {
              staticClass: "rcih-left",
              on: { click: _vm.handleToggleContentShow }
            },
            [
              _c(
                "div",
                { staticClass: "iconify-icon lz-icon-xiala" },
                [_c("IconifyIcon", { attrs: { icon: "lz-icon-xiala" } })],
                1
              ),
              _c(
                "div",
                { staticClass: "iconify-icon lz-icon-qiye" },
                [_c("IconifyIcon", { attrs: { icon: "lz-icon-qiye" } })],
                1
              ),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    disabled: _vm.showName.length < 10,
                    enterable: false,
                    content: _vm.showName,
                    placement: "top"
                  }
                },
                [
                  _c("div", { staticClass: "tit" }, [
                    _c("span", [_vm._v(_vm._s(_vm.showName))])
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "iconify-icon lz-icon-jia",
              on: { click: _vm.handleAdd }
            },
            [_c("IconifyIcon", { attrs: { icon: "lz-icon-jia" } })],
            1
          )
        ]
      ),
      _c("el-collapse-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.item.isCollapseShow,
                expression: "item.isCollapseShow"
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: !_vm.item.loaded,
                expression: "!item.loaded"
              }
            ],
            class: { "r-c-i-content": true, "is-show": _vm.item.isCollapseShow }
          },
          [
            _vm.item.roles && _vm.item.roles.length > 0 && _vm.item.loaded
              ? _vm._l(_vm.item.roles, function(role) {
                  return _c("item-child", {
                    key: `${_vm.item.hotelVid}-${role.value}`,
                    attrs: {
                      id: `${_vm.item.hotelVid}-${role.value}`,
                      "active-role-id": _vm.activeRoleId,
                      role: role
                    },
                    on: {
                      "toggle-active": _vm.handleToggleActive,
                      edit: _vm.handleEdit
                    }
                  })
                })
              : _vm._e()
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }