<template>
<error-content code="500" :desc="$t('Oh～鬼知道服务器经历了什么～')" :src="src"></error-content>
</template>

<script>
import errorContent from './error-content.vue'

export default {
  name: 'error_500',
  components: {
    errorContent
  },
  data () {
    return {
      src: 'https://file.40017.cn/zhuketong/zhuketong/image/oms/page_500.png'
    }
  }
}
</script>
