import { intl } from "@tci18n/vue2"; // 身份证号验证规则
export const regexIdNumber = (rule, value, callback) => {
  if (value) {
    let flag = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/.test(value);
    if (!flag) {
      callback(new Error(intl.$t("请填写正确的身份证号")));
    } else {
      callback();
    }
  } else {
    if (rule.required) {
      callback(new Error(intl.$t("身份证号必填")));
    } else {
      callback();
    }
  }
};

// 手机号验证正则
export const regexMobile = (rule, value, callback) => {
  if (value) {
    let flag = /^1[3456789]\d{9}$/.test(value);
    if (!flag) {
      callback(new Error(intl.$t("请填写正确的手机号")));
    } else {
      callback();
    }
  } else {
    if (rule.required) {
      callback(new Error(intl.$t("手机号必填")));
    } else {
      callback();
    }
  }
};

// 邮箱验证正则
export const regexEmail = (rule, value, callback) => {
  if (value) {
    let flag = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(value);
    if (!flag) {
      callback(new Error(intl.$t("请填写正确邮箱")));
    } else {
      callback();
    }
  } else {
    if (rule.required) {
      callback(new Error(intl.$t("邮箱必填")));
    } else {
      callback();
    }
  }
};

// 文字验证正则 英文、数字、下划线
export const regexFont = (rule, value, callback) => {
  if (value) {
    let flag = /^\w+$/.test(value);
    if (!flag) {
      callback(new Error(intl.$t("内容只能是英文、数字、下划")));
    } else {
      callback();
    }
  } else {
    if (rule.required) {
      callback(new Error(intl.$t("内容必填")));
    } else {
      callback();
    }
  }
};

// 密码校验
export const regexPwd = (rule, value, callback) => {
  if (value) {
    let flag = /^(?![A-Z\d]+$)(?![a-z\d]+$)(?![A-Za-z]+$)[\dA-Za-z]{1,16}$/.test(value);
    if (!flag) {
      callback(new Error(intl.$t("密码需为8-16位、包含大小写字母和数字、不含特殊字符")));
    } else {
      // 检查是否有4个或以上连续的数字
      // for(let i = 0; i <= 6; i++) {
      // 	let numRegex = new RegExp((i).toString()+(i+1).toString()+(i+2).toString()+(i+3).toString());
      // 	if(numRegex.test(value)) return callback(new Error('密码存在4位连续或相同的字母/数字，请修改'));
      // }
      // // 检查是否有4个或以上连续的字母
      // for(let i = 0; i <= 22; i++) {
      // 	let charRegex = new RegExp(String.fromCharCode(97 + i) + String.fromCharCode(98 + i) + String.fromCharCode(99 + i) + String.fromCharCode(100 + i), 'i');
      // 	if(charRegex.test(value)) return callback(new Error('密码存在4位连续或相同的字母/数字，请修改'));
      // }
      // // 检查是否有4个或以上连续重复的字符
      // if (/(.)\1\1\1/.test(value)) return callback(new Error('密码存在4位连续或相同的字母/数字，请修改'));
      callback();
    }
  } else {
    if (rule.required) {
      callback(new Error(intl.$t("请输入密码")));
    } else {
      callback();
    }
  }
};

/**
 * 匹配 ip 地址正则
 * @param { String } value ip 地址
 */
export const regexIP = (value) => {
  const reg = /^((2([0-4]\d|5[0-5]))|[0-1]?\d{1,2})(\.((2([0-4]\d|5[0-5]))|[0-1]?\d{1,2})){3}$/;
  return reg.test(value);
};