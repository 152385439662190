<template>
<div class="modal-box page-box">
    <div class="modal-body mt-10">
      <search-list class="search-section" ref="searchList" :placeholder="$t('搜索酒店名')" :props="searchProps" :showRadio="true" :search-list="searchList" @search="handleSearchCallback" @cancel="handleSearchCancelCallback" @select-change="handleSearchSelectChangeCallback"></search-list>

      <div v-show="!isSearchingState()" class="tree-section pt-15 pb-15">
        <el-tree class="tree-view" ref="tree" node-key="id" :highlight-current="false" :empty-text="$t('暂无数据')" :data="treeData" :render-content="renderTreeContent" @node-expand="handleTreeNodeExpand"></el-tree>
      </div>
    </div>

    <div class="lz-modal__footer lz-modal__footer__line flex_shrink__0">
      <el-button type="primary" size="small" class="lz-modal__footer__button confirm" @click="handleSaveClick">{{$t('确定')}}</el-button>
      <el-button size="small" class="lz-modal__footer__button cancel" @click="handleCloseClick">{{$t('取消')}}</el-button>
    </div>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2"; // 酒店树视图节点类型（tree 视图数据引用源不同，字段上面需要做映射）
const TREE_NODE_TYPE_REGION = 'region_';
const TREE_NODE_TYPE_HOTEL = 'hotel_';

import { mapState, mapGetters } from 'vuex';
import SearchList from './search.vue';
import commonAPI from '../../common/api';
import orgCommonAPI from '../api/common';

/**
 * 酒店选择
 * @module @/view/organization
 */
export default {
  name: 'SelectHotel',
  components: {
    SearchList
  },
  props: {
    // 引用源（角色-分配酒店：role，成员-所属酒店：member）
    refer: {
      type: String,
      default: 'role'
    },

    hotelVid: {
      type: [Number, String],
      default: -1
    },

    hotelName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // search-list 组件 props
      searchProps: {
        value: 'hotelVid',
        label: 'hotelName'
      },
      searchKeyword: '',
      // 搜索结果
      searchList: undefined,

      // tree 视图数据
      treeData: [],

      // 选择的数据
      selectedValue: {
        hotelVid: this.hotelVid,
        hotelName: this.hotelName
      }
    };
  },
  computed: {
    ...mapState('org', [
    'deptTreeData']
    ),

    ...mapGetters([
    'userOrgCode',
    'userOrgName']
    ),

    // 引用来源是否是“成员-所属酒店”
    isReferMember() {
      return this.refer === 'member';
    },

    // tree root 节点是否是 disabled 状态
    rootDisbaled() {
      return this.deptTreeData.length === 0 || !this.deptTreeData[0].permission;
    },

    // 获取组件引用
    searchListRef() {
      return this.$refs['searchList'];
    },

    treeRef() {
      return this.$refs['tree'];
    }
  },
  created() {
    this.initTreeData();
  },
  methods: {
    /**
     * 设置视图 loading 状态
     */
    viewLoading(loading) {
      this.$emit('loading', loading);
    },

    /**
     * 是否处于搜索状态
     */
    isSearchingState() {
      return this.$refs['searchList'] != undefined && this.$refs['searchList'].isSearching;
    },


    /** 搜索相关 **/
    /**
     * 搜索酒店
     * @note 注意转换字段类型，防止类型不一致导致数据项选中异常
     * @param { String } keyword 搜素关键字
     */
    searchHotel(keyword) {
      const params = { hotelName: keyword };
      this.viewLoading(true);
      commonAPI.hotelListByName(params).then((res) => {
        this.viewLoading(false);
        if (res.code == 200 && res.data) {
          this.searchKeyword = keyword;
          res.data.forEach((item) => item.hotelVid += '');
          this.searchList = res.data;

          this.searchListRef.setSelected(this.selectedValue.hotelVid);
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Search hotel error: ', error.message);
      });
    },

    /**
     * 搜索事件回调
     */
    handleSearchCallback(value) {
      if (this.searchKeyword != value) {
        this.searchList = undefined;
        this.searchHotel(value);
      }
    },

    /**
     * 搜索取消事件回调
     */
    handleSearchCancelCallback() {
      this.searchList = undefined;
      this.searchKeyword = '';
    },

    /**
     * 搜索项 select 回调事件
     * @param { Object } data 数据项
     */
    handleSearchSelectChangeCallback(data) {
      this.selectedValue.hotelVid = data.hotelVid;
      this.selectedValue.hotelName = data.hotelName;
    },


    /** tree 相关 **/
    initTreeData() {
      let data = null;
      if (this.isReferMember && !this.rootDisbaled) {
        // 来自“成员-所属酒店”引用并且具有 root 节点查看权限：展示选择集团
        // tree 视图数据引用源不同，字段上面需要做映射
        data = {
          id: TREE_NODE_TYPE_REGION + 'bloc',
          name: intl.$t("集团"),
          type: TREE_NODE_TYPE_REGION,
          isLeaf: false,
          children: [{
            id: TREE_NODE_TYPE_HOTEL + this.userOrgCode,
            code: this.userOrgCode,
            name: this.userOrgName,
            isBloc: true,
            type: TREE_NODE_TYPE_HOTEL,
            isLeaf: true
          }]
        };
      }
      this.getHotelTree(data);
    },

    /**
     * 获取城市酒店 tree 数据
     * @param { Object|Null } data 拼接数据
     */
    getHotelTree(data) {
      this.viewLoading(true);
      orgCommonAPI.hotelTreeByRegion().then((res) => {
        this.viewLoading(false);
        if (res.code == 200 && res.data) {
          this.processHotelTree(data, res.data);
        }
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Get hotel tree error: ', error.message);
      });
    },

    /**
     * 城市酒店 tree 数据处理
     */
    processHotelTree(prefixData, data) {
      if (prefixData) {
        this.treeData.push(prefixData);
      }

      // 初次渲染第一层，其他层级异步加载
      data.forEach((item) => {
        const nodeData = {
          id: TREE_NODE_TYPE_REGION + item.code,
          code: item.code,
          name: item.name,
          hotels: item.hotels,
          child: item.children,
          type: TREE_NODE_TYPE_REGION,
          isLeaf: !item.children.length && !item.hotels.length
        };
        this.treeData.push(nodeData);
      });
    },

    /**
     * 城市酒店 tree 子节点数据处理
     * @param { Object } data 当前节点数据对象
     */
    processChildNodeData(data) {
      let childData = [];
      data.child.forEach((item) => {
        const nodeData = {
          id: data.type + item.code,
          code: data.code,
          name: item.name,
          hotels: item.hotels,
          child: item.children,
          type: data.type,
          isLeaf: !item.children.length && !item.hotels.length
        };
        childData.push(nodeData);
      });
      data.hotels.forEach((item) => {
        const nodeData = {
          id: TREE_NODE_TYPE_HOTEL + item.hotelVid,
          code: item.hotelVid + '',
          name: item.hotelName,
          type: TREE_NODE_TYPE_HOTEL,
          isLeaf: true
        };
        childData.push(nodeData);
      });

      this.treeRef.updateKeyChildren(data.id, childData);
    },


    /**
     * 树视图渲染
     */
    renderTreeContent(h, { node, data, store }) {
      node.isLeaf = data.isLeaf;

      let rowElements = [];
      let prefixElments = [];
      if (this.isNodeHotel(data)) {
        prefixElments.push(
          h('el-radio', {
            props: {
              value: this.selectedValue.hotelVid,
              label: data.code
            },
            class: 'tree-radio'
          }, '')
        );
      }
      prefixElments.push(
        h('span', {
          class: 'tree-node__title'
        }, data.name)
      );
      let that = this;
      rowElements.push(
        h('div', {
          class: 'tree-row__prefix display__flex align_items__center',
          on: {
            click: function () {
              if (that.isNodeHotel(data)) {
                if (data.code != that.selectedValue.hotelVid) {
                  that.selectedValue.hotelVid = data.code;
                  that.selectedValue.hotelName = data.name;
                }
              }
            }
          }
        }, prefixElments)
      );
      return h('div', {
        class: 'tree-row'
      }, rowElements);
    },

    /**
     * 节点是否是“酒店”
     * @returns { Boolean } true/false
     */
    isNodeHotel(data) {
      return data.type === TREE_NODE_TYPE_HOTEL;
    },

    /**
     * 树节点展开事件
     * @note 如果子节点数据未获取，则获取子节点数据并追加
     * @note 新渲染数据需要同步节点勾选状态
     */
    handleTreeNodeExpand(data, node, nodeEle) {
      if (!node.childNodes.length) {
        this.processChildNodeData(data);
      }
    },


    /** footer 操作按钮相关 **/
    /**
     * 保存点击事件
     */
    handleSaveClick() {
      if (!this.selectedValue.hotelVid) {
        this.$notice.error({
          title: intl.$t("操作失败"),
          message: intl.$t("请选择酒店")
        });
        return;
      }
      this.$emit('confirm', this.selectedValue);
    },

    /**
     * 关闭点击事件
     */
    handleCloseClick() {
      this.$emit('input', false);
    }
  }
};
</script>

<style lang="scss" src="../common/tree.scss" scoped>

</style>

<style lang="scss" scoped>
.modal-box {
    height: 100%;
    overflow: hidden;
  }

  .modal-body {
    height: calc(100% - 52px - 10px);
    overflow: hidden;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;

    .tree-section {
      height: calc(100% - 40px);
      overflow: auto;
    }
  }
</style>
