var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "org-container" }, [
    _c(
      "div",
      { staticClass: "org-c-inner" },
      [
        _c(
          "ElTabs",
          [
            _c(
              "ElTabPane",
              { attrs: { label: _vm.$t("账号") } },
              [_c("accountCom")],
              1
            ),
            _c(
              "ElTabPane",
              { attrs: { label: _vm.$t("角色") } },
              [_c("roleCom")],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }