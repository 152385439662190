<template>
<div class="user-avatar-dropdown pr-20">
    <el-dropdown placement="bottom" @command="handleDropdownCommand" @visible-change="handleDropdownVisibleChanged">
      <div class="avatar-view">
        <img :src="getUserAvator()"/>
        <label>{{userInfo.userName || ''}}</label>
        <div class="triangle-view"><div :class="['triangle-item', { rotate: dropdownVisible }]"></div></div>
      </div>
      
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item command="changePassword">修改密码</el-dropdown-item> -->
        <el-dropdown-item command="logout">{{$t('退出登录')}}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- 修改密码弹窗 -->
    <lz-dialog v-model="showPasswordDialog" class="change-password-dialog" name="change-password" width="360px" footer-hide :mask-closable="!dialogLoading" loading-enable :loading="dialogLoading" @change="handleDialogVisibleChangeCallback">
      <div slot="body">
        <change-password v-if="passwordDialogVisible" v-model="showPasswordDialog" @loading="loading => dialogLoading = loading"></change-password>
      </div>
    </lz-dialog>

    <!-- 退出编辑弹窗 -->
    <lz-dialog v-model="showLogoutDialog" class="logout-dialog" name="logout" :title="$t('是否退出登录？')" :content="$t('退出登录后，您将退出所有系统。如仅需退出当前系统，请点取消后关闭浏览器窗口。')" :confirmButtonText="$t('退出登录')" :mask-closable="!dialogLoading" loading-enable :loading="dialogLoading" @change="handleDialogVisibleChangeCallback" @confirm="handleLogoutConfirm"></lz-dialog>
  </div>
</template>

<script>
import systemHelper from '@/libs/system'
  import basicHelper from '@/view/common/basic'
  import ChangePassword from '../password/password.vue'
  
  export default {
    name: 'User',
    props: {
      userInfo: {
        type: Object,
      },
    },
    components: {
      ChangePassword,
    },
    data () {
      return {
        dropdownVisible: false,
        showPasswordDialog: false,
        passwordDialogVisible: false,
        showLogoutDialog: false,
        dialogLoading: false
      }
    },
    methods: {
      getUserAvator() {
        return this.userInfo.headImgUrl || require('@/assets/images/head-default.png');
      },

      /**
       * 头像下拉项点击事件
       */
      handleDropdownCommand (name) {
        switch (name) {
          case 'logout':
            this.showLogoutDialog = true;
            break;
          case 'changePassword':
            this.showPasswordDialog = true;
            this.passwordDialogVisible = true;
            break;
        }
      },
      
      /**
       * Dropdown 显示状态改变回调
       */
      handleDropdownVisibleChanged(visible) {
        this.dropdownVisible = visible;
      },

      /**
       * Dialog 显示状态变化事件回调
       */
      handleDialogVisibleChangeCallback (visible) {
        if (!visible) {
          setTimeout(() => {
            this.passwordDialogVisible = false;
            this.dialogLoading = false;
          }, 200);
        }
      },

      /**
       * 退出登录确认事件
       */
      handleLogoutConfirm() {
        this.dialogLoading = true;
        basicHelper.logout().then(() => {
          this.dialogLoading = false;
          this.showLogoutDialog = false;
          systemHelper.loginByOAuth('Y');
        }).catch(error => {
          this.dialogLoading = false;
        });
      }
    }
  }
</script>

<style lang="scss" src="./user.scss">

</style>
