<template>
<div class="org-container">
     <div class="org-c-inner">
            <ElTabs>
                <ElTabPane :label="$t('账号')">
                    <accountCom></accountCom>
                </ElTabPane>
                <ElTabPane :label="$t('角色')">
                    <roleCom></roleCom>
                </ElTabPane>
            </ElTabs>
        </div>
   </div>
</template>

<script>
import accountCom from './newAccount/index.vue'
 import roleCom from './newRole/index.vue'
export default {
    name: 'Organization',
    components: {
        accountCom,
        roleCom
    }
}
</script>

<style lang="scss" scoped>
.org-container {
        padding: 16px;
    }
    .org-c-inner{
        background-color: #fff;
        padding: 16px;
        :deep(.el-tabs__header) {
            margin-bottom: 0 !important;
        }
        :deep(.el-tabs__nav-wrap::after) {
            height: 1px;
            background-color: #EFF4FF;
        }
        :deep(.el-tabs__active-bar) {
            background-color: #4A7CF6;
        }
        :deep(.el-tabs__item) {
            font-weight: 400;
            font-size: 14px;
            color: #575B6B;
            &.is-active {
                font-weight: 500;
                color: #283145;
            }
        }
    }
</style>
