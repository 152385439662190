
const a = process.env.VUE_APP_BASE_API

export default {
  /**
   * @description 项目名称
   */
  projectName: 'hrms',

  /**
   * @description 是否使用国际化，默认为false
   *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
   *              用来在菜单中显示文字
   */
  useI18n: false,

  /**
   * @description 后端服务域名配置
   */
  serverConfig: {
    serviceURL: a ,  // "main 服务"域名
  },

  /**
   * @description api 服务相关配置
   */
  apiConf: {

    serviceApi: '/ehr/' // “main 服务”二级目录
  }
}
