/**
 * 公共 API
 */
import config from '@/config'
import Ajax from '@/libs/ajax.js'

const ajax = new Ajax(config.serverConfig.serviceURL);

/* 基础数据相关
-------------------------- */
// 菜单、页面及按钮权限信息
const menuPageInfo = ajax.post('menu/list');
// 用户信息
const userInfo = ajax.post('/sso/user');
// 退出 登录
const logout = ajax.post('/sso/logout');


// 修改密码
const changePassword = ajax.post(config.apiConf.serviceApi + 'pwd/modify');
// 检查账号是否存在
const checkAccountExists = ajax.post(config.apiConf.serviceApi + 'userInfo/checkExistedAccount');
// 检查手机号是否存在
const checkMobileExists = ajax.post(config.apiConf.serviceApi + 'userInfo/checkExistedMobile');



/* 酒店相关
-------------------------- */
// 酒店列表（权限内）
const hotelListByName = ajax.post(config.apiConf.serviceApi + 'hotel/listByFuzzyName');


export default {
  menuPageInfo,
  userInfo,
  changePassword,
  logout,
  checkAccountExists,
  checkMobileExists,

  hotelListByName
}
