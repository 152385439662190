var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    {
      on: { command: _vm.handleItemClick },
      scopedSlots: _vm._u([
        {
          key: "dropdown",
          fn: function() {
            return [
              _c(
                "el-dropdown-menu",
                _vm._l(_vm.langList, function(i) {
                  return _c(
                    "el-dropdown-item",
                    { key: i.value, attrs: { command: i.value } },
                    [_vm._v(_vm._s(i.label))]
                  )
                }),
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("span", { staticClass: "f-center" }, [
        _vm._v(_vm._s(_vm.currentLang)),
        _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }