<template>
  <div class="role-collapse-container">
    <roleCollapseItem
      v-for="(item, index) in hotelList"
      :item="item"
      :key="index"
      :group-name="groupName"
      :active-role-id="activeRoleId"
      :is-collapse-show="item.isCollapseShow"
      @toggle-content-show="handleToggleContentShow"
      @add="handleAdd"
      @toggle-active="handleToggleActive"
      @edit="handleEdit"
    />
  </div>
</template>

<script>
import roleCollapseItem from './item.vue'

export default {
  name: 'roleCollapse',
  components: {
    roleCollapseItem,
  },
  props: {
    hotelList: {
      type: Array,
      default: () => [],
    },
    groupName: {
      type: String,
      default: '',
    },
    activeRoleId: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleToggleContentShow(hotel, isShow) {
      this.$emit('toggle-content-show', hotel, isShow)
    },
    handleAdd(hotel) {
        this.$emit('add', hotel)
    },
    handleToggleActive(roleId, hotel) {
        this.$emit('toggle-active',roleId, hotel)
    },
    handleEdit(role, hotel) {
        this.$emit('edit', role, hotel)
    }
  },
}
</script>

<style lang="scss" scoped>
.role-collapse-container{
    padding: 10px 0;
}
</style>
