/*
* 同程旅行（https://www.ly.com/）.
* 苏公网安备32059002001003号 © 2002-2023 ly.com All Rights Reserved. | 苏ICP备09033604号.
* @Date: 2024.01.18.
* @Author
* @Description 本地 存储 二次封装 .
**/
/**
 *@Description local Storage 二次封装.
 *@Return
**/
export const setStorage = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value))
}

export const getStorage = (key) => {
    const storedStr = window.localStorage.getItem(key)
    if (storedStr) {
        return JSON.parse(storedStr)
    }
    return null
}
export const removeStorage = (key) => {
    window.localStorage.removeItem(key)
}
export const clearStorage = () => {
    window.localStorage.clear()
}

/**
 *@Description session Storage 二次封装.
 *@Return
**/
export const setSession = (key, value) => {
    window.sessionStorage.setItem(key, JSON.stringify(value))
}
export const getSession = (key) => {
    const storedStr = window.sessionStorage.getItem(key)
    if (storedStr) {
        return JSON.parse(storedStr)
    }
    return null
}
export const removeSession = (key) => {
    window.sessionStorage.removeItem(key)
}
export const clearSession = () => {
    window.sessionStorage.clear()
}
/**
 * window.local Storage 浏览器永久缓存
 * @method set 设置永久缓存
 * @method get 获取永久缓存
 * @method remove 移除永久缓存
 * @method clear 移除全部永久缓存
 */
export const Local = {
    // 设置永久缓存
    set(key, val) {
        window.localStorage.setItem(key, JSON.stringify(val))
    },
    // 获取永久缓存
    get(key) {
        const json = window.localStorage.getItem(key)
        return JSON.parse(json)
    },
    // 移除永久缓存
    remove(key) {
        window.localStorage.removeItem(key)
    },
    // 移除全部永久缓存
    clear() {
        window.localStorage.clear()
    }
}

/**
 * window.session Storage 浏览器临时缓存
 * @method set 设置临时缓存
 * @method get 获取临时缓存
 * @method remove 移除临时缓存
 * @method clear 移除全部临时缓存
 */
export const Session = {
    // 设置临时缓存
    set(key, val) {
        window.sessionStorage.setItem(key, JSON.stringify(val))
    },
    // 获取临时缓存
    get(key) {
        const json = window.sessionStorage.getItem(key)
        return JSON.parse(json)
    },
    // 移除临时缓存
    remove(key) {
        window.sessionStorage.removeItem(key)
    },
    // 移除全部临时缓存
    clear() {
        window.sessionStorage.clear()
    }
}
