
/**
 * 组织 - 状态管理
 */
export default {
  namespaced: true,
  state: {
    // 资源池类型（组织架构：dept、角色：role）
    poolType: 'dept',

    // 组织架构 tree 数据
    deptTreeData: null,
    // 通用角色数据
    generalRoleData: [],
    // 当前选择的部门
    activeDept: null,
    // 当前选择的角色
    activeRole: null,

    // 当前编辑的成员信息
    activeMember: null,
    // 成员操作事件
    memberAction: '',

    // loading 标识（进入组织页面设置 true，如果进入「组织架构」视图，则部门数据获取为空或者默认选中部门下的成员获取后设置 false，如果进入「角色」视图，则角色数据获取为空或者默认选中角色下的成员获取后设置 false）
    pageLoading: false,
    // 页面事件（如：首页-快捷入口）
    pageAction: ''
  },
  getters: {
    /**
     * 资源池类型是否是“组织架构”
     * @returns { Boolean } true/false
     */
    isPoolTypeDept: state => {
      return state.poolType === 'dept';
    },

    /**
     * 判断组织架构树节点是否是根节点
     * @note 根节点 deptId 为 0
     * @param { Number } nodeId 节点Id
     */
    isTreeRootNode: state => nodeId => {
      return nodeId == 0;
    },

    /**
     * 获取当前选中的部门 id
     * @returns { Number|String } deptId 
     */
    activeDeptId: state => {
      return state.activeDept ? state.activeDept.deptId : '';
    },

    /**
     * 获取当前选中的角色 id
     * @returns { Number|String } roleId 
     */
    activeRoleId: state => {
      return state.activeRole ? state.activeRole.roleId : '';
    },

    /**
     * 获取当前编辑的成员 id
     * @returns { Number|Undefined } id
     */
    activeMemberId: state => {
      return state.activeMember ? state.activeMember.id : undefined;
    }
  },
  mutations: {
    /**
     * 设置资源池类型
     * @param { String } type（组织架构：dept，角色：role）
     */
    setPoolType (state, type) {
      state.poolType = type;
    },

    /**
     * 设置组织架构树数据
     * @param { Array|Null } data tree data
     */
    setDeptTreeData (state, data) {
      state.deptTreeData = data;
    },

    /**
     * 设置通用角色数据
     * @param { Array } data role data
     */
    setGeneralRoleData (state, data) {
      state.generalRoleData = data;
    },

    /**
     * 设置当前选中部门
     * @param { Object|Null } data 部门数据
     */
    setActiveDept (state, data) {
      if (data) {
        state.activeDept = { deptId: data.deptId, deptName: data.deptName };
      } else {
        state.activeDept = data;
      }
    },

    /**
     * 设置当前选中角色
     * @param { Object|Null } data 角色数据
     */
    setActiveRole (state, data) {
      if (data) {
        state.activeRole = { roleId: data.id, roleName: data.name };
      } else {
        state.activeRole = data;
      }
    },

    /**
     * 设置当前编辑的成员信息
     * @param { Object } data 成员信息
     */
    setActiveMember (state, data) {
      state.activeMember = data;
    },

    /**
     * 设置成员操作事件
     * @param { String } action 事件名称
     */
    setMemberAction(state, action) {
      state.memberAction = action;
    },

    /**
     * 设置加载中标识
     * @param { Boolean } loading 是否是 loading 状态
     */
    setPageLoading(state, loading) {
      state.pageLoading = loading;
    },

    /**
     * 设置页面事件
     * @param { String } action 事件名称
     */
    setPageAction(state, action) {
      state.pageAction = action;
    },
  }
}
