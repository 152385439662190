<template>
<el-dialog :visible="visible" :title="$t('重置密码')" :close-on-click-modal="false" @close="handleClose" width="360px">
    <div class="pwd-container" v-loading="loading">
      <el-form ref="formRef" :show-message="false" :model="formData" label-position="top" size="mini">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="userPwd" required>
              <template #label>
                <div class="label-custome">
                  <p>{{$t('新密码')}}</p>
                  <span>{{$t('（您也可以手动设置密码）')}}</span>
                </div>
              </template>
              <el-input v-model="formData.userPwd" :placeholder="$t('请输入新密码')" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <div>
        <el-button @click="handleClose">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="handleConfirm">{{$t('确定')}}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { intl } from "@tci18n/vue2";import orgCommonApi from '@/view/organization/api/common';
export default {
  name: 'OrgUpdatePWD',

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  watch: {
    visible(val) {
      if (val) {
        console.log('visible', val);
        this.generatePassword();
      }
    }
  },
  data() {
    return {
      loading: false,
      formData: {
        userPwd: ''
      }
    };
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
    generatePassword() {
      try {
        orgCommonApi.pwdGenerate().then((res) => {
          if (res.code == 200 && res.data) {
            this.formData.userPwd = res.data;
          }
        });
      } catch (err) {
        console.error(intl.$t("获取密码失败"), err);
      }
    },

    handleConfirm() {
      this.$refs['formRef'].validate((valid) => {
        if (valid) {
          orgCommonApi.
          pwdReset({
            userId: this.detail.userId,
            userPwd: this.formData.userPwd
          }).
          then((res) => {
            if (res.code == 200) {
              this.$emit('update:visible', false);
              this.$emit('submit:success');
              this.$notice.success(intl.$t("密码重置成功"));
            } else {
              this.$notice.error(res.message);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.pwd-container {
  margin-top: -20px;
  margin-bottom: -20px;
  .label-custome {
    display: inline-flex;
    align-items: center;
    span {
      font-size: 12px;
      color: #c6cdd9;
    }
  }
}
</style>
